
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import th from "@element-plus/locale/lang/th.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import Password from "vue3-password-strength-meter";
import axios from "axios";
const typeRegister = ref("1");
const checkIdcard = ref(false);
const modelRegister = ref({
  email: "",
  password: "",
  cpassword: "",
  prefix: "",
  firstName: "",
  lastName: "",
  idCard: "",
  birthDate: "",
  phoneNumber: "",
  regionId: "",
  regionTh: "",
  provinceId: "",
  districtId: "",
  subDistrictId: "",
  provinceTh: "",
  provinceEn: "",
  districtTh: "",
  subDistrictTh: "",
  zipcode: "",
  road: "",
  no: "",
  moo: "",
  soi: "",
  qualificationId: "",
  qualificationOther: "",
  qualificationMajor: "",
  officeTypeId: "",
  officeExperience: "",
  officePosition: "",
  officeJobDesc: "",
  toc: false,
  imageProfile: "",
  file: null,
});
const modelMandatory = ref({
  companyElectric: "",
  companyTransformer: "",
  companyHeat: "",
  companyFuel: "",
  machines: [],
  machineOther: "",
  technologies: [],
  technologieOther: "",
  warrantorFirstname: "",
  warrantorLastname: "",
  warrantorPosition: "",
  warrantorOffice: "",
  qualificationImages: [],
  qualificationAvatar: "",
  delImages: [],
  fileAvatar: null,
  fileImages: [],
  imagesList: [],
});
const mandatory = ref([
  {
    facId: "",
    preFactoryNo: "",
    preBuildingNo: "",
    officeTypeId: "",
    factorySizeId: "",
    factoryTypes: [],
    factoryTypeOther: "",
    buildingSizeId: "",
    buildingTypes: [],
    buildingTypeOther: "",
    search: "",
  },
]);
const factory = ref([
  {
    address: "",
    subDistrictName: "",
    districtName: "",
    provinceName: "",
    zipcode: "",
  },
]);
const currentAdress = ref();
export default defineComponent({
  name: "sign-up",
  components: {
    // Form,
    // Password,
  },
  data() {
    return {
      locale: th,
      imageUrl: process.env.VUE_APP_API_URL,
      provinceList: [],
      districtList: [],
      subDistrictList: [],
      qualificationList: [],
      officeTypeList: [],
      myCourseTypeList: [],
      myCourseSize: 0,
      factoriesList: [{}],
      buildingSizesList: [],
      buildingTypesList: [],
      technologiesList: [],
      machinesList: [],
      factorySizesList: [],
      factoryTypesList: [],
      validate: 0,
      validateCpassword: true,
      passwordFieldType: "password",
      passwordFieldType2: "password",
      prefixList: [],
      accessToken: null,
      regionList: null,
    };
  },

  setup() {
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const router = useRouter();
    const tab1 = ref(false);
    const tab2 = ref(false);
    const tab3 = ref(false);
    const tab4 = ref(false);
    // modelRegister: {},

    const submitButton = ref<HTMLElement | null>(null);
    const checkRequire = () => {
      if (
        modelRegister.value.email &&
        modelRegister.value.password &&
        modelRegister.value.cpassword &&
        modelRegister.value.prefix &&
        modelRegister.value.firstName &&
        modelRegister.value.lastName &&
        // modelRegister.value.idCard &&
        // modelRegister.value.birthDate &&
        modelRegister.value.phoneNumber &&
        modelRegister.value.qualificationOther &&
        modelRegister.value.qualificationMajor &&
        // modelRegister.value.addressNo &&
        // modelRegister.value.provinceId &&
        // modelRegister.value.districtId &&
        // modelRegister.value.subDistrictId &&
        modelRegister.value.qualificationId &&
        // modelRegister.value.officePosition &&
        modelRegister.value.toc &&
        checkIdcard.value == true
      ) {
        tab1.value = true;
      } else {
        tab1.value = false;
      }
      // && tab2.value && tab3.value && tab4.value
      if (
        tab1.value &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          modelRegister.value.email
        )
      ) {
        return true;
      } else {
        return false;
      }
    };
    const onSubmitRegister = async () => {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const machines: any = [];
      for (const loopMachine of modelMandatory.value.machines) {
        machines.push({
          machineId: loopMachine,
          other: loopMachine == 6 ? modelMandatory.value.machineOther : "",
        });
      }

      const technologies: any = [];
      for (const loopTech of modelMandatory.value.technologies) {
        technologies.push({
          techId: loopTech,
          other: loopTech == 6 ? modelMandatory.value.technologieOther : "",
        });
      }
      if (checkRequire()) {
        let birthDate;
        birthDate =
          modelRegister.value.birthDate.split("/")[1] +
          "/" +
          modelRegister.value.birthDate.split("/")[0] +
          "/" +
          parseInt(modelRegister.value.birthDate.split("/")[2]);
        birthDate = new Date(birthDate).getTime();
        const formData = new FormData();
        formData.append("email", modelRegister.value.email);
        formData.append("username", modelRegister.value.email);
        formData.append("password", modelRegister.value.password);
        formData.append("prefix ", modelRegister.value.prefix);
        formData.append("firstName", modelRegister.value.firstName);
        formData.append("lastName", modelRegister.value.lastName);
        formData.append("idCard", modelRegister.value.idCard);
        formData.append("birthDate", birthDate);
        formData.append("regionCode", modelRegister.value.regionId);
        formData.append("regionTh", currentAdress.value.region_c);
        formData.append("provinceCode", modelRegister.value.provinceId);
        formData.append("provinceTh", currentAdress.value.province_th);
        formData.append("provinceEn", currentAdress.value.province_en);
        formData.append("districtCode", modelRegister.value.districtId);
        formData.append("districtTh", currentAdress.value.city_th);
        formData.append("subDistrictCode", modelRegister.value.subDistrictId);
        formData.append("subDistrictTh", currentAdress.value.precinct_th);
        formData.append("zipcode", modelRegister.value.zipcode);
        formData.append("road", modelRegister.value.road);
        formData.append("no", modelRegister.value.no);
        formData.append("moo", modelRegister.value.moo);
        formData.append("soi", modelRegister.value.soi);
        formData.append("phoneNumber", modelRegister.value.phoneNumber);
        formData.append("qualificationId", modelRegister.value.qualificationId);
        formData.append(
          "qualificationOther",
          modelRegister.value.qualificationOther
        );
        formData.append(
          "qualificationMajor",
          modelRegister.value.qualificationMajor
        );
        formData.append("subDistrictId", modelRegister.value.subDistrictId);
        formData.append("officeTypeId", modelRegister.value.officeTypeId);
        formData.append(
          "officeExperience",
          modelRegister.value.officeExperience
        );
        formData.append("officeJobDesc", modelRegister.value.officeJobDesc);
        formData.append("officePosition", modelRegister.value.officePosition);
        /* eslint-disable */
        if (modelRegister.value.file != null) {
          const formUploadImage = new FormData();
          formUploadImage.append("file ", modelRegister.value.file!);
          let uploadImage = await axios.post(
            process.env.VUE_APP_API_URL + "/uploadImage",
            formUploadImage,
            { headers: { token: localStorage.getItem("tokenGuest") } }
          );
          // console.log(uploadImage);
          formData.append("imageProfile", uploadImage.data.data!);
        }
        axios
          .post(process.env.VUE_APP_API_URL + "/register", formData)
          .then((res) => {
            if (res.data.code == 200) {
              Swal.fire({
                title: "ลงทะเบียนสำเร็จ",
                text: "กรุณาตรวจสอบอีเมลเพื่อยืนยันตัวตน",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then(function() {
                router.go(-1);
              });
            } else {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: res.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text:
                error.response != undefined
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      } else {
        Swal.close();
      }
    };
    const addFactory = () => {
      mandatory.value.push({
        facId: "",
        preFactoryNo: "",
        preBuildingNo: "",
        officeTypeId: "",
        factorySizeId: "",
        factoryTypes: [],
        factoryTypeOther: "",
        buildingSizeId: "",
        buildingTypes: [],
        buildingTypeOther: "",
        search: "",
      });
      factory.value.push({
        address: "",
        subDistrictName: "",
        districtName: "",
        provinceName: "",
        zipcode: "",
      });
    };
    // onMounted(() => {
    //   store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
    //     qulifiedName: "style",
    //   });
    // });

    // store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
    //   qulifiedName: "style",
    //   value: "background-image: url(media/patterns/header-bg.jpg)",
    // });
    const backPage = () => {
      router.go(-1);
    };
    const setToc = () => {
      modelRegister.value.toc = true;
      hideModal(newTargetModalRef.value);
    };
    const assertThaiId = (thaiId: string): boolean => {
      // console.log("assertThaiId : " + thaiId);
      const m = thaiId.match(/(\d{12})(\d)/);
      if (!m) {
        console.warn("Bad input from user, invalid thaiId=", thaiId);
        throw new Error("thai-id-must-be-13-digits");
      }
      const digits = m[1].split("");
      const sum = digits.reduce((total: number, digit: string, i: number) => {
        return total + (13 - i) * +digit;
      }, 0);
      const lastDigit = `${(11 - (sum % 11)) % 10}`;
      const inputLastDigit = m[2];
      if (lastDigit !== inputLastDigit) {
        console.warn("Bad input from user, invalid checksum thaiId=", thaiId);
        // throw new Error("thai-id-checksum-mismatched");
        checkIdcard.value = false;
        return false;
      } else {
        checkIdcard.value = true;
        return true;
      }
    };
    return {
      // registration,
      onSubmitRegister,
      submitButton,
      modelRegister,
      checkRequire,
      tab1,
      tab2,
      tab3,
      tab4,
      modelMandatory,
      typeRegister,
      backPage,
      mandatory,
      addFactory,
      factory,
      setToc,
      hideModal,
      newTargetModalRef,
      assertThaiId,
      checkIdcard,
      currentAdress,
    };
  },
  computed: {
    isValidEmail() {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        modelRegister.value.email
      );
    },
  },
  methods: {
    validateThai(input, value) {
      const thaiRegex = /^[\u0E00-\u0E7F]+$/;
      let check = thaiRegex.test(value);
      if (input == "firstName" && check != true) {
        modelRegister.value.firstName = "";
      }
      if (input == "lastName" && check != true) {
        modelRegister.value.lastName = "";
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    switchVisibility2() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
    },
    onFileChange(event) {
      modelRegister.value.file = event.target.files[0];
      modelRegister.value.imageProfile = URL.createObjectURL(
        event.target.files[0]
      );
    },
    onFileChangeMultiple(event) {
      const deleteFile: any = [];
      if (modelMandatory.value.imagesList) {
        for (const loopDel of modelMandatory.value.imagesList) {
          deleteFile.push(loopDel);
        }
      }
      modelMandatory.value.delImages = deleteFile;
      modelMandatory.value.fileImages = event.target.files;
      if (event.target.files.length > 0 && event.target.files.length == 3) {
        const image: any = [];
        for (const loopFile of event.target.files) {
          image.push(URL.createObjectURL(loopFile));
        }
        modelMandatory.value.qualificationImages = image;
      } else {
        alert("จำนวนรูไม่ถูกต้อง");
      }
    },
    onFileChangeAvatar(event) {
      modelMandatory.value.fileAvatar = event.target.files[0];
      modelMandatory.value.qualificationAvatar = URL.createObjectURL(
        event.target.files[0]
      );
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      // eslint-disable-next-line prefer-const
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async callProvince(regionId, clear) {
      if (clear == true) {
        modelRegister.value.provinceId = "";
        modelRegister.value.districtId = "";
        modelRegister.value.subDistrictId = "";
        modelRegister.value.zipcode = "";
      }
      const provinces = await axios.get(
        "https://etraining.dede.go.th/api/xProvince?region_c=" + regionId,
        {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );
      this.provinceList = provinces.data;
    },
    async callDistrict(provinceId, clear) {
      if (clear == true) {
        modelRegister.value.districtId = "";
        modelRegister.value.subDistrictId = "";
        modelRegister.value.zipcode = "";
      }
      const districts = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getDistrictTraining?province_c=" +
          provinceId
      );
      this.districtList = districts.data.data;
    },
    async callSubDistrict(districtId, clear) {
      if (clear == true) {
        modelRegister.value.subDistrictId = "";
        modelRegister.value.zipcode = "";
      }
      let subDistricts = await axios.get(
        `https://etraining.dede.go.th/api/xAdm_districts?province_c=${modelRegister.value.provinceId}&city_c=${districtId}`,
        {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );
      this.subDistrictList = subDistricts.data;
    },
    callZipcode(subDistrictId, clear) {
      if (clear == true) {
        modelRegister.value.zipcode = "";
      }
      if (this.subDistrictList.length > 0) {
        for (const loopData of this.subDistrictList) {
          if (loopData["precinct_c"] == subDistrictId) {
            currentAdress.value = loopData;
            modelRegister.value.zipcode = loopData["post_code"];
          }
        }
      }
    },

    async callFactories(index) {
      const factories = await axios.get(
        process.env.VUE_APP_API_URL +
          "/api/v1/m/factories?search=" +
          mandatory.value[index].search
      );
      this.factoriesList[index] = factories.data.result;
    },
    async callFactoriesId(index) {
      const factorylist = await axios.get(
        process.env.VUE_APP_API_URL +
          "/api/v1/m/factories/" +
          mandatory.value[index].facId
      );
      factory.value[index] = factorylist.data.result;
      mandatory.value[index].search = factory.value[index]["factoryName"];
      this.callFactories(index);
    },
    // setBuildingTypes(id) {
    //   if (
    //     modelMandatory.value &&
    //     modelMandatory.value.buildingTypes.length > 0
    //   ) {
    //     for (const loopdata of modelMandatory.value.buildingTypes) {
    //       if (loopdata["buildingTypeId"] == id) {
    //         return true;
    //       }
    //     }
    //   } else {
    //     return false;
    //   }
    // },
    fileMultipleClick() {
      // console.log(this.$refs.fileMultiple);
      const input: any = this.$refs.fileMultiple;
      input.click();
    },
    fileAvatarClick() {
      // console.log(this.$refs.fileAvatar);
      const input: any = this.$refs.fileAvatar;
      input.click();
    },
    fileClick() {
      // console.log(this.$refs.file);
      const input: any = this.$refs.file;
      input.click();
    },
    validatePassword() {
      this.validate = 0;
      const letterRegex = /[a-zA-Z]/;
      const numberRegex = /[0-9]/;
      const symbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

      const isValidLength = modelRegister.value.password.length >= 8;
      const hasLetter = letterRegex.test(modelRegister.value.password);
      const hasNumber = numberRegex.test(modelRegister.value.password);
      const hasSymbol = symbolRegex.test(modelRegister.value.password);

      if (isValidLength) {
        this.validate = this.validate + 1;
      }
      if (hasLetter) {
        this.validate = this.validate + 1;
      }
      if (hasNumber) {
        this.validate = this.validate + 1;
      }
      if (hasSymbol) {
        this.validate = this.validate + 1;
      }
      // console.log("validatePassword");
      // console.log(this.validate);
      // return isValidLength && hasLetter && hasNumber && hasSymbol;
    },
    validateConfirmpassword() {
      if (modelRegister.value.password == modelRegister.value.cpassword) {
        this.validateCpassword = true;
      } else {
        this.validateCpassword = false;
      }
    },
    async xTrainee(idCard) {
      // let xTrainee = await axios.get(
      //   "https://etraining.dede.go.th/api/xTrainee/" + idCard,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + this.accessToken,
      //     },
      //   }
      // );
      // console.log(xTrainee);
      await axios
        .get("https://etraining.dede.go.th/api/xTrainee/" + idCard, {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        })
        .then((res) => {
          console.log(res.data);
          modelRegister.value.prefix = res.data.prename_th;
          modelRegister.value.firstName = res.data.first_name_th;
          modelRegister.value.lastName = res.data.last_name_th;
          modelRegister.value.phoneNumber = res.data.telephone.replace(
            /-/g,
            ""
          );
          modelRegister.value.email =
            res.data.email != null && res.data.email != "-"
              ? res.data.email
              : "";
          modelRegister.value.no = res.data.addr_no ? res.data.addr_no : "";
          modelRegister.value.moo = res.data.moo != null ? res.data.moo : "";
          modelRegister.value.soi = res.data.moo != null ? res.data.soi : "";
          modelRegister.value.road =
            res.data.street != null ? res.data.street : "";
          modelRegister.value.provinceId =
            res.data.province_c != null ? res.data.province_c : "";
          modelRegister.value.districtId =
            res.data.city_c != null ? res.data.city_c : "";
          modelRegister.value.subDistrictId =
            res.data.precinct_c != null ? res.data.precinct_c : "";
          modelRegister.value.zipcode =
            res.data.postcode != null ? res.data.postcode : "";
          if (modelRegister.value.provinceId) {
            this.callDistrict(modelRegister.value.provinceId, false);
          }
          if (modelRegister.value.districtId) {
            this.callSubDistrict(modelRegister.value.districtId, false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    if (localStorage.getItem("reloaded")) {
      location.reload();
      localStorage.removeItem("reloaded");
    }
    const provinces = await axios.get(
      process.env.VUE_APP_API_URL + "/getProvince"
    );
    this.provinceList = provinces.data.data;
    // console.log(provinces.data);

    const qualifications = await axios.get(
      process.env.VUE_APP_API_URL + "/getQualification"
    );
    this.qualificationList = qualifications.data.data;

    const officeTypes = await axios.get(
      process.env.VUE_APP_API_URL + "/getOfficeType"
    );
    this.officeTypeList = officeTypes.data.data;
    let etrainingToken = await axios.get(
      process.env.VUE_APP_API_URL + "/connectTrainingToken"
    );
    this.accessToken = etrainingToken.data.data.access_token;
    console.log(etrainingToken.data.data.access_token);
    let xPrename = await axios.get(
      "https://etraining.dede.go.th/api/xPrename",
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      }
    );
    this.prefixList = xPrename.data;
    let xRegion = await axios.get("https://etraining.dede.go.th/api/xRegion", {
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
    console.log(xRegion);
    this.regionList = xRegion.data;
    let xProvince = await axios.get(
      "https://etraining.dede.go.th/api/xProvince",
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      }
    );
    this.provinceList = xProvince.data;
  },
});
